import { CART_ID_KEY, USER_KEY } from '@shared/constants';
import config from '../../data/config.json';

export type UserData = {
  email: string;
  owner_id: string;
  accessToken: string;
  refreshToken: string;
};

export const getUserSession = (): UserData => {
  const { projectName } = config;
  try {
    const userJson = sessionStorage.getItem(`${projectName}${USER_KEY}`);
    const user = userJson ? JSON.parse(userJson) : null;
    return user;
  } catch (error) {
    console.error('ERROR GETTING USER SESSSION', error);
  }
};

export const setUserSession = (userData: UserData) => {
  const { projectName } = config;
  try {
    sessionStorage.setItem(`${projectName}${USER_KEY}`, JSON.stringify(userData));
  } catch (error) {
    console.error('ERROR SETTING USER SESSSION', error);
  }
};

export const deleteUserSession = () => {
  const { projectName } = config;
  try {
    sessionStorage.removeItem(`${projectName}${USER_KEY}`);
  } catch (error) {
    console.error('ERROR DELETING USER SESSSION', error);
  }
};

export const getCartIdSession = (): string => {
  const { projectName } = config;
  try {
    return sessionStorage.getItem(`${projectName}${CART_ID_KEY}`);
  } catch (error) {
    console.error('ERROR GETTING CART ID SESSSION', error);
  }
};

export const setCartIdSession = (id: string) => {
  const { projectName } = config;
  try {
    sessionStorage.setItem(`${projectName}${CART_ID_KEY}`, id);
  } catch (error) {
    console.error('ERROR SETTING CART ID SESSSION', error);
  }
};

export const deleteCartIdSession = () => {
  const { projectName } = config;
  try {
    sessionStorage.removeItem(`${projectName}${CART_ID_KEY}`);
  } catch (error) {
    console.error('ERROR DELETING CART ID SESSSION', error);
  }
};
