import { authenticate } from '@commercelayer/js-auth';
import type {
  BraintreePayment,
  Bundle,
  CommerceLayerClient,
  Address as CommerceLayerSdkAddress,
  Customer,
  CustomerAddress,
  LineItem,
  LineItemCreate,
  LineItemOption,
  Metadata,
  Order,
  OrderUpdate,
  Shipment,
  Sku,
  StockItem,
} from '@commercelayer/sdk';
import CommerceLayer from '@commercelayer/sdk';
import { FakeAddress } from '@model/common';
import { Level, ProductAndBundleDataType } from '@model/product';
import { BillingAddressFormData } from '@src/types/address';
import { api } from '@utils/config';
import {
  UserData,
  deleteCartIdSession,
  deleteUserSession,
  getCartIdSession,
  getUserSession,
  setCartIdSession,
  setUserSession,
} from '@utils/session-storage';
import { AxiosError } from 'axios';
import addresses from '../../data/addresses.json';
import {
  trackAddToCart,
  trackRemoveFromCart,
  trackSignupEnd,
  trackSignupStart,
} from './utils/gtmUtils';

const defaultHeaders = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
};

const SRV_URL = process.env.SRV_URL;

type CommerceLayerUserData = {
  email: string;
  password: string;
  accessToken: string;
  refreshToken: string;
  owner_id: string;
};

type CommerceLayerProduct = {
  skuCode: string;
  name: string;
  image?: string;
  option?: { id: string; description: string; name: string };
  maxQuantity?: string;
  levels?: string;
  url?: string;
  type: string;
  id: string;
  imageUrl: string;
  code?: string;
  metadata: { maxQuantity: string; levels: Level[]; url: string };
};

export type CommerceLayerAddress = {
  id: string;
  lastName: string;
  firstName: string;
  line1: string;
  line2: string;
  city: string;
  company: string;
  zipCode: string;
  stateCode: string;
  countryCode: string;
  phone: string;
  metadata: Metadata;
  sdi: string;
  iban: string;
  pec: string;
};

export type AddressType = 'billing' | 'shipping';

export type ConstructorType = {
  marketId: string;
  countryCode: string;
  endpoint: string;
  clientId: string;
};

export class AppCommerceLayerClient {
  private marketId = '';
  private countryCode = '';
  private keys: { endpoint: string; clientId: string };
  private order: Order | undefined = undefined;
  private token: any | null = null;
  public customer: Customer | undefined = undefined;
  private commerceLayerSdk: CommerceLayerClient;
  private apiClientUrl: string = '';

  constructor(params: ConstructorType) {
    const { marketId, countryCode, endpoint, clientId } = params;
    this.marketId = marketId;
    this.countryCode = countryCode;
    this.keys = { endpoint, clientId };

    if (this.keys) {
      this.apiClientUrl = this.keys.endpoint + '/api';
    }
  }

  public async init() {
    if (!this.marketId) {
      return;
    }
    const { clientId, endpoint } = this.keys;
    const scope = `market:${this.marketId}`;
    const user = getUserSession();
    if (user) {
      await this.loginFromSession(user);
    } else {
      try {
        const token = await authenticate('client_credentials', {
          clientId: clientId,
          scope: scope,
        });
        this.token = token;
        this.commerceLayerSdk = CommerceLayer({
          organization: this.organizationFromEndPoint(endpoint),
          accessToken: token?.accessToken || '',
        });
      } catch (error) {
        this.logError('init ERROR', error);
        throw error;
      }
    }
  }

  public async sleep(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  public getOrderId() {
    return getCartIdSession();
  }

  public cleanup() {
    deleteCartIdSession();
    deleteUserSession();
  }

  private organizationFromEndPoint(endpoint: string) {
    return endpoint.split('://')[1].split('.')[0];
  }

  // PRODUCT

  public async getProductList(skuCode: string[]): Promise<Sku[] | undefined> {
    try {
      const PAGE_SIZE = 25;
      let numPages = Math.ceil(skuCode.length / PAGE_SIZE);

      let skus: Sku[] = [];
      for (let pageNumber = 1; pageNumber < numPages + 1; pageNumber++) {
        const response = await this.commerceLayerSdk.skus.list({
          filters: { code_matches_any: skuCode.join(',') },
          include: ['sku_options', 'prices', 'stock_items', 'stock_items.reserved_stock'],
          pageNumber: pageNumber,
          pageSize: PAGE_SIZE,
        });
        skus.push(...response!);
      }

      this.calculateSkusInventoryFromStockItems(skus);

      return skus;
    } catch (error) {
      this.logError('getProductList', error);
    }
  }

  public async getProduct(skuCode: string): Promise<Sku | null | undefined> {
    if (!skuCode) {
      return null;
    }
    try {
      const skus = await this.commerceLayerSdk.skus.list({
        filters: { code_matches_any: skuCode },
      });
      if (!skus?.first()) {
        return null;
      }

      //skus.list doesn't return inventory object so we need to do a skus.retrieve call
      const sku = await this.commerceLayerSdk.skus.retrieve(skus.first()!.id, {
        include: ['sku_options', 'prices', 'stock_items', 'stock_items.reserved_stock'],
      });

      if (sku) {
        this.calculateSkusInventoryFromStockItems([sku]);
      }

      return sku;
    } catch (error) {
      this.logError('getProduct', error);
    }
  }

  // BUNDLE

  public async getBundleList(skuCode: string[]) {
    try {
      const PAGE_SIZE = 25;
      let numPages = Math.ceil(skuCode.length / PAGE_SIZE);

      let bundles: Bundle[] = [];
      for (let pageNumber = 1; pageNumber < numPages + 1; pageNumber++) {
        const response = await this.commerceLayerSdk.bundles.list({
          filters: { code_matches_any: skuCode.join(',') },
          include: [
            'skus',
            'skus.stock_items',
            'sku_list.sku_list_items',
            'skus.stock_items.reserved_stock',
          ],
          pageNumber: pageNumber,
          pageSize: PAGE_SIZE,
        });
        bundles.push(...response!);
      }

      bundles.forEach((bundle: Bundle) => {
        this.calculateSkusInventoryFromStockItems(bundle.skus);
      });

      return bundles;
    } catch (error) {
      this.logError('getBundleList', error);
    }
  }

  public async getBundle(skuCode: string) {
    if (!skuCode) {
      return null;
    }
    try {
      const bundles = await this.commerceLayerSdk.bundles.list({
        filters: { code_matches_any: skuCode },
      });
      if (!bundles?.first()) {
        return null;
      }

      //bundles.list doesn't return inventory object so we need to do a bundles.retrieve call
      const bundle = await this.commerceLayerSdk.bundles.retrieve(bundles.first()!.id, {
        include: [
          'skus',
          'skus.stock_items',
          'sku_list.sku_list_items',
          'skus.stock_items.reserved_stock',
        ],
      });

      if (bundle && bundle.skus) {
        this.calculateSkusInventoryFromStockItems(bundle.skus);
      }

      return bundle;
    } catch (error) {
      this.logError('getBundle', error);
    }
  }

  private calculateSkusInventoryFromStockItems = (skus: Sku[]) => {
    skus.forEach((sku) => {
      const quantity = this.sumStockItemsQuantity(sku);
      sku.inventory = { ...sku.inventory, quantity, available: quantity > 0 };
    });
  };

  private sumStockItemsQuantity = (sku: Sku) =>
    (sku.stock_items || []).reduce((qty, stockItem) => {
      const _stockItem = stockItem as StockItem & { reserved_stock?: { quantity: number } };
      return (qty += (_stockItem?.quantity || 0) - (_stockItem.reserved_stock?.quantity || 0));
    }, 0);

  public getUserSalesChannelToken = async (username: string, password: string) => {
    const { clientId } = this.keys;
    const scope = `market:${this.marketId}`;
    return await authenticate('password', {
      clientId: clientId,
      scope: scope,
      username: username,
      password: password,
    });
  };

  private async loginFromSession(user: UserData) {
    try {
      //reload user by accesstoken saved
      const token = await this.renewToken(user.refreshToken);
      this.token = token;
      const userData: UserData = {
        email: user.email,
        owner_id: user.owner_id,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      };
      await this.postLoginOperations(userData);
    } catch (error) {
      this.logError('loginFromSession ERROR', error);
      this.checkError(error);
    }
  }

  public async login(user: CommerceLayerUserData) {
    try {
      const { email: username, password } = user;
      const token = await this.getUserSalesChannelToken(username, password);
      console.log(token);
      if (token.errors) {
        throw 'WRONG_CREDENTIALS';
      }
      this.token = token;

      const userData: UserData = {
        email: user.email,
        owner_id: token.ownerId,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      };

      await this.postLoginOperations(userData);
    } catch (error) {
      if (error != 'WRONG_CREDENTIALS') {
        this.logError('LOGIN ERROR', error);
      }

      throw error;
    }
  }

  private async postLoginOperations(userData: UserData) {
    const { endpoint } = this.keys;
    this.commerceLayerSdk = CommerceLayer({
      organization: this.organizationFromEndPoint(endpoint),
      accessToken: this.token.accessToken,
    });

    let customer = null;
    if (userData.owner_id) {
      customer = await this.commerceLayerSdk.customers.retrieve(userData.owner_id, {
        include: ['customer_addresses.address'],
      });
      this.customer = customer;
      setUserSession(userData);
    }

    if (customer && customer.customer_addresses) {
      const addresses = customer.customer_addresses.filter(
        (a) => a.address?.country_code === this.countryCode
      );
      if (addresses.length > 0) {
        await this.migrateAddressesIfNeeded(addresses);
      }
    }

    if (customer) {
      if (!this.order) {
        await this.getOrder();
      }
      await this.addCustomerToOrder();
    }
  }

  private async migrateAddressesIfNeeded(customerAddresses: CustomerAddress[]) {
    const oldVersionAddresses = this.extractOldAddresses(customerAddresses);
    if (oldVersionAddresses.length > 0) {
      for await (const customerAddress of oldVersionAddresses) {
        if (customerAddress.address) {
          const addressType: AddressType = customerAddress.address.metadata?.fiscalCode
            ? 'billing'
            : 'shipping';
          await this.updateAddress(customerAddress.address, addressType);
        }
      }
    }
  }

  private extractOldAddresses(customerAddresses: CustomerAddress[]) {
    return customerAddresses.filter(
      (customerAddress) =>
        !(
          customerAddress.address?.metadata &&
          'isBilling' in customerAddress.address?.metadata &&
          'isShipping' in customerAddress.address?.metadata
        )
    );
  }

  public async register(user: CommerceLayerUserData, metadata: string, subscribeConsent = false) {
    const { email, password } = user;
    trackSignupStart(subscribeConsent);
    return await this.doRegister({ email, password, metadata, subscribeConsent });
  }

  private async doRegister(attributes: {
    email: string;
    password: string;
    metadata: string;
    subscribeConsent: boolean;
  }) {
    const url = `${SRV_URL}/signup`;
    try {
      const res = await api.post(url, attributes, { headers: this.headers });
      if (res.data.error) {
        trackSignupEnd(false);
      } else {
        trackSignupEnd(true);
      }

      return res.data;
    } catch (error) {
      trackSignupEnd(false);
      throw error;
    }
  }

  public async logout() {
    this.cleanup();
    this.customer = undefined;
    await this.init();
    await this.createOrder();
  }

  private async renewToken(refreshToken: string) {
    const { clientId, endpoint } = this.keys;
    const scope = `market:${this.marketId}`;
    return await authenticate('refresh_token', {
      clientId: clientId,
      scope: scope,
      refreshToken: refreshToken,
    });
  }

  //@deprecated use countOrderProductsQuantity
  public async countQuantity() {
    const lineItems = await this.getLineItems();
    if (!lineItems) {
      return 0;
    }
    return lineItems
      .filter((i) => i.item_type === 'skus' || i.item_type === 'bundles')
      .map((lineItem) => lineItem.quantity)
      .reduce((acc, value) => acc + value, 0);
  }

  public countOrderProductsQuantity(order: Order): number {
    const lineItems = order?.line_items;
    if (!lineItems) {
      return 0;
    }
    return lineItems
      .filter((i) => i.item_type === 'skus' || i.item_type === 'bundles')
      .map((lineItem) => lineItem.quantity)
      .reduce((acc, value) => acc + value, 0);
  }

  public async addToCart(
    product: ProductAndBundleDataType,
    indexGtm: number,
    incQuantity = 1,
    itemListName: string
  ) {
    let result = {
      error: undefined as unknown,
      success: true,
      image: product.image,
      name: product.name,
      incQuantity,
    };

    const { skuCode, name, image, option, maxQuantity, levels, url } = product;
    let order = await this.getOrder();
    if (!order) {
      order = await this.createOrder();
    }
    const lineItems = order?.line_items;
    const existingLineItem = lineItems?.find((li) => li.sku_code === skuCode);
    if (existingLineItem) {
      const quantity = incQuantity + existingLineItem.quantity!;
      try {
        await this.updateLineItemQuantity(existingLineItem, quantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('CART UPDATE error', error);
        result = { ...result, error, success: false };
      }
    } else {
      try {
        const quantity = incQuantity;
        const lineItem = await this.commerceLayerSdk.line_items.create({
          order: order,
          quantity,
          sku_code: skuCode,
          name,
          image_url: image.src,
          metadata: { maxQuantity, levels, url },
        });
        if (option) {
          const skuOption = await this.commerceLayerSdk.sku_options.retrieve(option.id);
          if (skuOption) {
            await this.commerceLayerSdk.line_item_options.create({
              name: option.name,
              quantity,
              options: { description: option.description },
              line_item: lineItem,
              sku_option: skuOption,
            });
          }
        }

        trackAddToCart(lineItem, incQuantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('CART CREATE error', error);
        result = { ...result, error, success: false };
      }
    }
    return result;
  }

  public async addBundleToCart(
    product: ProductAndBundleDataType,
    indexGtm: number,
    incQuantity = 1,
    itemListName: string
  ) {
    let result = {
      error: undefined as unknown,
      success: true,
      image: product.image,
      name: product.name,
      incQuantity,
    };
    const { skuCode, name, image, maxQuantity, levels, type = 'skus', id, url, metadata } = product;
    let order = await this.getOrder();
    if (!order) {
      order = await this.createOrder();
    }
    const existingLineItem = order?.line_items?.find((li: LineItem) =>
      li.sku_code ? li.sku_code === skuCode : li.bundle_code === skuCode
    );

    if (existingLineItem) {
      const quantity = incQuantity + existingLineItem.quantity!;
      try {
        await this.updateLineItemQuantity(existingLineItem, quantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('BUNDLE UPDATE error', error);
        result = { ...result, error, success: false };
      }
    } else {
      const quantity = incQuantity;
      try {
        const lineItem = await this.createLineItem(
          order?.id || '',
          {
            type,
            id,
            skuCode,
            name,
            imageUrl: image.src || '',
            metadata: {
              ...metadata,
              maxQuantity: maxQuantity || '',
              levels: levels || [],
              url: url || '',
            },
          },
          quantity
        );

        lineItem.sku_code = lineItem.bundle_code;

        trackAddToCart(lineItem, quantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('BUNDLE CREATE error', error);
        result = { ...result, error, success: false };
      }
    }
    return result;
  }

  public async removeLineItem(lineItem: LineItem) {
    try {
      await this.commerceLayerSdk.line_items.delete(lineItem.id);
      trackRemoveFromCart(lineItem);
    } catch (error) {
      this.logError('removeLineItem error', error);
    }
  }

  private async getLineItems() {
    const order = await this.getOrder();
    if (!order) return [];
    return order?.line_items;
  }

  public async checkEtagUpdate(currentOrderEtag?: string): Promise<Order | undefined> {
    let retries = 0;
    let commerceLayerEtag = null;
    while (retries < 10) {
      const order = await this.getOrder();
      commerceLayerEtag = order?.metadata?.etag;
      if (commerceLayerEtag && commerceLayerEtag !== currentOrderEtag) {
        return order;
      }
      retries += 1;
      await this.sleep(1000);
    }
  }

  public async refreshOrder(): Promise<Order> {
    return this.commerceLayerSdk.orders._refresh(this.getOrderId());
  }

  private checkError(error: any) {
    if (error && error.toArray) {
      const items = error.toArray();
      if (items[0] && ['UNAUTHORIZED', 'INVALIDTOKEN'].includes(items[0].code)) {
        this.cleanup();
        this.order = undefined;
        this.init();
      }
    }
  }

  public async fetchOrder(id: string) {
    try {
      const order = await this.commerceLayerSdk.orders.retrieve(id, {
        include: [
          'line_items',
          'billing_address',
          'shipping_address',
          'available_payment_methods',
          'shipments.available_shipping_methods',
          'payment_method',
          'payment_source',
          'customer.customer_addresses.address',
        ],
      });
      this.order = order;
      return order;
    } catch (error) {
      this.logError('fetchOrder', error);
      this.checkError(error);
    }
  }

  private async addCustomerToOrder() {
    if (!this.customer) {
      return;
    }
    if (
      this.order &&
      this.order.customer_email &&
      this.order.customer_email === this.customer.email
    ) {
      return;
    }
    if (this.order?.id && this.customer?.id) {
      await this.setOrderCustomer(this.order.id, this.customer.id);
    }
  }

  public async addCustomerEmail(id: string, email: string) {
    return await this.commerceLayerSdk.orders.update({ id, customer_email: email });
  }

  public async setAddress(id: string, address: string, type: string) {
    try {
      await this.commerceLayerSdk.orders.update({ id, [type]: address });
    } catch (error) {
      this.logError('setAddress error', error);
    }
  }

  //API

  private getHeaders() {
    const headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${this.token?.accessToken}`,
    };
    return headers;
  }

  //API

  public async setPaymentMethod(orderId: string, paymentMethodId: string): Promise<Order> {
    try {
      const order = await this.commerceLayerSdk.orders.update({
        id: orderId,
        payment_method: this.commerceLayerSdk.payment_methods.relationship(paymentMethodId),
      });
      return order;
    } catch (error) {
      this.logError('setPaymentMethod error', error);
      throw error;
    }
  }

  public async deletePaymentSource(orderId: string, type: string, id: string) {
    const url = `${this.apiClientUrl}/${type}/${id}`;
    const res = await api.delete(url, { headers: this.getHeaders() }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data, error.response.status, error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
      console.error(error.config);
    });
    if (res) {
      return res.data;
    }
  }

  public async setPaymentSource(orderId: string, type = 'braintree_payments') {
    const url = `${this.apiClientUrl}/${type}`;
    try {
      const res = await api.post(
        url,
        {
          data: {
            type,
            attributes: {},
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: orderId,
                },
              },
            },
          },
        },
        { headers: this.getHeaders() }
      );

      return res.data;
    } catch (error) {
      this.logError('setPaymentSource error', error);
      throw error;
    }
  }

  public async updateBraintreePaymentSource(
    sourceId: string,
    payment_method_nonce: string | null
  ): Promise<BraintreePayment> {
    try {
      const res = await this.commerceLayerSdk.braintree_payments.update({
        id: sourceId,
        payment_method_nonce,
      });
      return res;
    } catch (error) {
      this.logError('updateBraintreePaymentSource error', error);
      throw error;
    }
  }

  public async placeOrder(
    orderId: string,
    shipId: string,
    billId: string,
    metadata = {},
    locale = 'en'
  ): Promise<Order> {
    try {
      const res = await this.commerceLayerSdk.orders.update({
        id: orderId,
        _place: true,
        language_code: locale,
        metadata: metadata,
        _shipping_address_clone_id: shipId,
        _billing_address_clone_id: billId,
      });
      return res;
    } catch (error) {
      this.logError('placeOrder error', error);
      throw error;
    }
  }

  private async setOrderCustomer(orderId: string, customerId: string): Promise<Order> {
    try {
      const res = await this.commerceLayerSdk.orders.update({
        id: orderId,
        customer: this.commerceLayerSdk.customers.relationship(customerId),
      });
      return res;
    } catch (error) {
      this.logError('setOrderCustomer error', error);
      throw error;
    }
  }

  public async addAddressToCustomer(
    customerId: string,
    addressId: string
  ): Promise<CustomerAddress> {
    try {
      // @ts-expect-error no customer_email needed
      const res = this.commerceLayerSdk.customer_addresses.create({
        customer: this.commerceLayerSdk.customers.relationship(customerId),
        address: this.commerceLayerSdk.addresses.relationship(addressId),
      });
      return res;
    } catch (error) {
      this.logError('addAddressToCustomer', error);
    }
  }

  public async setAddresses(attributes: {
    orderId: string;
    billId?: string;
    shipId?: string;
  }): Promise<Order> {
    try {
      const updateObjet: OrderUpdate = {
        id: attributes.orderId,
        //_billing_address_clone_id: attributes.billId,
        //_shipping_address_clone_id: attributes.shipId,
      };
      if (attributes.billId) {
        updateObjet.billing_address = this.commerceLayerSdk.addresses.relationship(
          attributes.billId
        );
      }
      if (attributes.shipId) {
        updateObjet.shipping_address = this.commerceLayerSdk.addresses.relationship(
          attributes.shipId
        );
      }
      const res = await this.commerceLayerSdk.orders.update(updateObjet, {
        include: ['billing_address', 'shipping_address'],
      });

      return res;
    } catch (error) {
      this.logError('setAddresses', error);
    }
  }

  public async setShipping(attributes: {
    shipmentId: string;
    shippingMethodId: string;
  }): Promise<Shipment> {
    try {
      const res = await this.commerceLayerSdk.shipments.update({
        id: attributes.shipmentId,
        shipping_method: this.commerceLayerSdk.shipping_methods.relationship(
          attributes.shippingMethodId
        ),
      });
      return res;
    } catch (error) {
      this.logError('setShipping', error);
      throw error;
    }
  }

  public async removeCoupon(attributes: { orderId: string }): Promise<Order> {
    try {
      const res = await this.commerceLayerSdk.orders.update({
        id: attributes.orderId,
        coupon_code: null,
      });
      return res;
    } catch (error) {
      this.logError('removeCoupon', error);
      throw error;
    }
  }

  public async addCoupon(attributes: { orderId: string; code: string }) {
    const url = `${this.apiClientUrl}/orders/${attributes.orderId}`;
    try {
      const res = await api.patch(
        url,
        {
          data: {
            type: 'orders',
            id: attributes.orderId,
            attributes: {
              gift_card_or_coupon_code: attributes.code,
            },
          },
        },
        { headers: this.getHeaders() }
      );
      return res.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response.status == 422) {
          //invalid coupon
          throw error;
        }
      }
      this.logError('addCoupon', error);
      throw error;
    }
  }

  public async subscribeToProfilingOrNewsletter(attributes: {
    email: string;
    metadata: { consent_type: string };
  }) {
    const url = `${this.apiClientUrl}/customer_subscriptions`;
    const { email, metadata } = attributes;
    try {
      const res = await api.post(
        url,
        {
          data: {
            type: 'customer_subscriptions',
            attributes: {
              customer_email: email,
              reference: metadata?.consent_type || 'Newsletter',
              metadata,
            },
          },
        },
        { headers: this.getHeaders() }
      );
      return res.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        // Planned with the wineplatform team:
        // If there is a 422 error, we ignore the error.
        // The customer_subscriptions was called 2 times, probably using a guest user when you check the profiling or marketing checkbox.
        if (error.response?.status === 422) {
          return;
        } else {
          // console.error('error', error);
          throw error;
        }
      }
    }
  }

  private get headers() {
    return { accept: 'Accept: application/json', 'content-type': 'application/json' };
  }

  public async resetPassword(attributes: string) {
    const url = `${SRV_URL}/resetPassword`;
    const res = await api.post(url, attributes, { headers: this.headers });
    return res.data;
  }

  public async changePassword(attributes: string) {
    const url = `${SRV_URL}/changePassword`;
    const res = await api.post(url, attributes, { headers: this.headers });
    return res.data;
  }

  public async updateCustomer(attributes: Record<string, string>) {
    const url = `${SRV_URL}/updateCustomer`;
    const id = this.customer?.id;
    const accessToken = this.token.accessToken;
    const res = await api.post(url, { id, accessToken, attributes }, { headers: this.headers });
    return res.data;
  }

  public async updateAddress(
    attributes: BillingAddressFormData | CommerceLayerSdkAddress,
    addressType: AddressType
  ): Promise<CommerceLayerSdkAddress> {
    try {
      const res = await this.commerceLayerSdk.addresses.update({
        id: attributes.id,
        ...this.mapAttributesForCommerceLayer(attributes, addressType),
      });
      return res;
    } catch (error) {
      this.logError('updateAddress', error);
      throw error;
    }
  }

  public async createAddress(
    attributes: CommerceLayerSdkAddress | BillingAddressFormData,
    addressType: AddressType
  ) {
    try {
      const newAttributes = this.mapAttributesForCommerceLayer(attributes, addressType);
      const response = await this.commerceLayerSdk.addresses.create(newAttributes);
      return response;
    } catch (error) {
      this.logError('createAddress', error);
      throw error;
    }
  }

  public mapAttributesForCommerceLayer(
    attributes: BillingAddressFormData | CommerceLayerSdkAddress,
    addressType: AddressType
  ) {
    const company = 'company' in attributes ? attributes.company : undefined;
    const metadata = 'metadata' in attributes ? attributes.metadata : {};
    return {
      business: !!company,
      first_name: attributes.first_name,
      last_name: attributes.last_name,
      company,
      line_1: attributes.line_1 || '',
      line_2: attributes.line_2 || '',
      city: attributes.city || '',
      zip_code: attributes.zip_code,
      state_code: attributes.state_code || '',
      country_code: attributes.country_code || '',
      phone: attributes.phone || '',
      metadata: {
        ...{ ...metadata, ...attributes, id: undefined, metadata: undefined },
        isShipping: addressType === 'shipping',
        isBilling: addressType === 'billing',
        lastUsage: new Date().getTime(),
      },
    };
  }

  private async createLineItem(
    orderId: string,
    product: CommerceLayerProduct,
    qt = 1
  ): Promise<LineItem> {
    const { id, imageUrl, code, name, metadata, type = 'skus' } = product;
    try {
      const lineItem: LineItemCreate = {
        order: this.commerceLayerSdk.orders.relationship(orderId),
        quantity: qt,
        name,
        bundle_code: type === 'bundles' ? code : null,
        sku_code: type === 'skus' ? code : null,
        image_url: imageUrl,
        metadata,
      };
      if (type == 'skus') {
        lineItem.item = this.commerceLayerSdk.skus.relationship(id);
      } else if (type == 'bundles') {
        lineItem.item = this.commerceLayerSdk.bundles.relationship(id);
      } else {
        console.error('createLineItem unexpected type');
      }
      const res = this.commerceLayerSdk.line_items.create(lineItem);
      return res;
    } catch (error) {
      this.logError('createLineItem error', error);
    }
  }

  public async updateItemAvailability(
    itemId: string,
    availability: number | undefined
  ): Promise<LineItem> {
    try {
      const res = await this.commerceLayerSdk.line_items.update({
        id: itemId,
        quantity: availability,
        metadata: { availability },
      });
      return res;
    } catch (error) {
      this.logError('updateItemAvailability', error);
      throw error;
    }
  }

  private async updateLineItemQty(id: string, quantity: number): Promise<LineItem> {
    try {
      const res = await this.commerceLayerSdk.line_items.update({
        id: id,
        quantity: quantity,
      });
      return res;
    } catch (error) {
      this.logError('updateLineItemQty', error);
      throw error;
    }
  }

  private async updateLineItemOptionQty(id: string, quantity: number): Promise<LineItemOption> {
    try {
      const res = await this.commerceLayerSdk.line_item_options.update({
        id: id,
        quantity: quantity,
      });
      return res;
    } catch (error) {
      this.logError('updateLineItemOptionQty', error);
      throw error;
    }
  }

  // NEW

  public async getOrder(checkStatus = true): Promise<Order> {
    const cartId = this.getOrderId();
    try {
      let order = undefined;

      if (!cartId) {
        return order;
      }
      try {
        order = await this.fetchOrder(cartId);
      } catch (err) {
        deleteCartIdSession();
      }

      if (!order) {
        return await this.createOrder();
      }
      if (checkStatus) {
        if (order.status !== 'pending' && order.status !== 'draft') {
          return await this.createOrder();
        }
      }
      this.order = order;
      return order;
    } catch (error) {
      this.logError('GET ORDER ERROR', error);
      if (cartId) {
      }
      this.checkError(error);
    }
  }

  private async createOrder(): Promise<Order> {
    const defaults = {
      shipping_country_code_lock: this.countryCode,
    };

    const shippingAddress = await this.getShippingAddress();

    const order = await this.commerceLayerSdk.orders.create({
      ...defaults,
      ...(!!shippingAddress && { shipping_address: shippingAddress }),
    });

    if (!order) {
      throw 'ERROR, CANNOT CREATE AN ORDER';
    }

    setCartIdSession(order.id);
    this.order = order;
    return order;
  }

  private async getShippingAddress() {
    try {
      const address = addresses.find(
        (sc: FakeAddress) => sc.countryCode.toUpperCase() == this.countryCode.toUpperCase()
      );
      if (address) {
        const { id } = address;
        const shippingAddress = await this.commerceLayerSdk.addresses.retrieve(id);
        return shippingAddress;
      }
    } catch (error) {
      this.logError('getShippingAddress', error);
    }
    return null;
  }

  public async updateLineItemQuantity(
    lineItem: LineItem,
    incOrDecQuantity: number,
    indexGtm: number,
    itemListName: string
  ) {
    const { id, quantity } = lineItem;
    const updatedLineItem = await this.updateLineItemQty(lineItem.id, incOrDecQuantity);
    const findItem = await this.commerceLayerSdk.line_items.retrieve(id, {
      include: ['line_item_options'],
    });
    const options = findItem?.line_item_options;
    options.forEach(async (option) => {
      await this.updateLineItemOptionQty(option.id, incOrDecQuantity);
    });
    //Handle for increment or decrement items from cart page
    if (quantity && quantity >= incOrDecQuantity) {
      trackRemoveFromCart(lineItem, quantity - incOrDecQuantity);
    } else if (quantity) {
      trackAddToCart(lineItem, incOrDecQuantity - quantity, indexGtm, itemListName);
    }
  }

  public getCustomer = () => this.customer;

  public async deleteAddressAndCustomerAddressesById(
    customerAddressesId: string,
    addressId: string
  ) {
    await this.commerceLayerSdk.customer_addresses.delete(customerAddressesId);
    await this.commerceLayerSdk.addresses.delete(addressId);

    await this.verifyOrderConsistency(addressId);
  }

  private verifyOrderConsistency = async (addressId: string) => {
    const orderId = this.getOrderId();
    if (orderId) {
      const order = await this.fetchOrder(orderId);

      const billId =
        order?.billing_address?.id === addressId ? undefined : order?.billing_address?.id;
      const shipId =
        order?.shipping_address?.id === addressId ? undefined : order?.shipping_address?.id;

      if (!billId || !shipId) {
        this.setAddresses({ orderId, billId, shipId });
      }
    }
  };

  public async getAddressList(market: string) {
    if (this.customer) {
      const PAGE_SIZE = 25;

      let goOn = true;
      let pageNumber = 0;
      let customerAddresses = [];
      while (goOn) {
        pageNumber += 1;
        const response = await this.commerceLayerSdk.customer_addresses.list({
          include: ['address'],
          pageNumber,
          pageSize: PAGE_SIZE,
        });
        if (!response || response?.length < PAGE_SIZE) {
          goOn = false;
        }
        customerAddresses.push(...response!);
      }

      const ignoreMarket = !market;
      if (customerAddresses) {
        return customerAddresses
          .filter(
            (customerAddress) =>
              this.isValidCustomerAddress(customerAddress) &&
              (ignoreMarket || customerAddress.address?.country_code === market)
          )
          .map((customerAddress) => ({
            ...customerAddress,
            address: { ...this.mapAddressForUI(customerAddress.address) },
          }));
      }
    }
    return [];
  }

  private isValidCustomerAddress(customerAddress: CustomerAddress) {
    return customerAddress.address?.metadata?.type !== 'test';
  }

  public async getAddressById(id: string) {
    const address = await this.commerceLayerSdk.addresses.retrieve(id);
    return this.mapAddressForUI(address);
  }

  private mapAddressForUI(address: CommerceLayerSdkAddress | undefined) {
    if (address) {
      return {
        ...address,
        person_type: address?.metadata?.person_type,
        fiscal_code: address?.metadata?.fiscal_code,
        vat: address?.metadata?.vat,
        sdi: address?.metadata?.sdi,
        iban: address?.metadata?.iban,
      };
    } else {
      throw Error('No address found');
    }
  }

  public upsertAddress = async (
    addressFormData: CommerceLayerSdkAddress | BillingAddressFormData,
    addressType: AddressType
  ) => {
    if (addressFormData.id) {
      return await this.updateAddress(addressFormData, addressType);
    } else {
      const result = await this.createAddress(addressFormData, addressType);
      return await this.commerceLayerSdk.customer_addresses.create({
        customer_email: this.customer.email,
        customer: { id: this.customer!.id, type: 'customers' },
        address: { id: result!.id, type: 'addresses' },
      });
    }
  };

  private logError(context: string, error: any) {
    if ('errors' in error) {
      console.error(context, error.errors);
    } else {
      console.error(context, error);
    }
  }
}
