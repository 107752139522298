import { ToastErrorIcon } from '@components/icons/ToastErrorIcon';
import { ToastSuccessIcon } from '@components/icons/ToastSuccessIcon';
import { useAppContext } from '@lib/context/appContext';
import { TOAST_TIMEOUT } from '@shared/constants';
import React, { useEffect } from 'react';

export const Toast: React.FC = () => {
  const { state, dispatch } = useAppContext();
  const {
    toast: { show, message, error, autoClose = true, action },
  } = state;

  const close = () => {
    dispatch({ type: 'HIDE_TOAST', data: false });
    if (action) {
      action();
    }
  };

  useEffect(() => {
    if (autoClose) {
      setTimeout(close, TOAST_TIMEOUT);
    }
  }, [show]);

  return (
    show && (
      <div className={error ? 'toast--error' : 'toast'} onClick={close}>
        <div className="svg-wrapper">{error ? <ToastErrorIcon /> : <ToastSuccessIcon />}</div>
        <div className="toast-message">{message}</div>
      </div>
    )
  );
};
