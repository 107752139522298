import { LineItem, Order } from '@commercelayer/sdk';
import { ProductAndBundleDataType } from '@model/product';
import { COUNTRY_CODE_KEY, GTM_DATA_KEY } from '@shared/constants';
import { localStorageGetItem, localStorageSetItem } from '@utils/local-storage';
//@ts-ignore
import Cookies from 'js-cookie';
import config from '../../../data/config.json';
import markets from '../../../data/markets.json';

declare const dataLayer: Record<string, unknown>[];

export const saveUtmParams = (queryParams: any) => {
  const utmAllowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const utmKeys = Object.keys(queryParams)
    .filter((key) => utmAllowedKeys.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = queryParams[key];
      return obj;
    }, {});
  if (Object.keys(utmKeys).length > 0) {
    Cookies.set('utm_params', JSON.stringify(utmKeys), { expires: 30 });
  }
};

export const getUtmParams = () => {
  const utmParams = Cookies.get('utm_params');
  if (utmParams) {
    return JSON.parse(utmParams);
  }
};

export const trackSignupStart = (subscribeConsent: any) => {
  try {
    const payload: any = {
      event: 'sign_up',
      event_detail: 'standard',
      event_subdetail: null,
      event_voice: subscribeConsent ? 'y' : 'n',
      event_id: '03',
      destination_url: null,
      event_trigger: 'Tevent',
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(payload);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackSignupEnd = (eventSuccess: any) => {
  try {
    const payload: any = {
      event: 'sign_up',
      event_detail: eventSuccess ? 'success' : 'error',
      event_subdetail: null,
      event_voice: null,
      event_id: '03',
      destination_url: null,
      event_trigger: 'Tevent',
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(payload);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackLogin = () => {
  try {
    const payload: any = {
      event: 'login',
      event_detail: 'standard',
      event_subdetail: null,
      event_voice: null,
      event_id: '02',
      destination_url: null,
      event_trigger: 'Tevent',
    };

    if (typeof dataLayer !== 'undefined' && !dataLayer.find((item) => item.event === 'login')) {
      dataLayer.push(payload);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackGenericView = (pageType: any) => {
  try {
    const payload = {
      ...getGTMVariablesFromMarket(),
      ...{
        page_type: pageType,
      },
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.unshift(payload); //forced to first position
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackProductView = (data: ProductAndBundleDataType) => {
  try {
    data.metadata.maxQuantity = data.maxQuantity;
    const lineItem: LineItem = {
      id: data.id,
      type: 'line_items',
      item_type: data.type == 'bundles' ? 'bundles' : 'skus',
      name: data.name,
      sku_code: data.skuCode,
      unit_amount_float: data.price.amount_float,
      quantity: 0,
      total_amount_float: data.price.amount_float,
      tax_amount_float: 0,
      created_at: null,
      updated_at: null,
      metadata: data.metadata,
    };
    const payload = {
      ...{
        event: 'view_item',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: data.price.currency_code,
          value: parseFloat(data.price.amount_float.toFixed(2)),
          items: gtmProducts([lineItem], true),
        },
      },
    };
    pushEcommAtIndex(1, payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackProductSelectView = (
  item_list_name: string,
  data: ProductAndBundleDataType,
  productIndex: number
) => {
  data.maxQuantity = 1;
  try {
    const payload = {
      ...{
        event: 'select_item',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: data.price.currency_code,
          items: gtmSelectProducts(data, item_list_name, productIndex),
          item_list_name,
        },
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackProductListView = (
  item_list_name: string,
  products: { forcedIndex: number; skuCode: string; name: string; price: number }[]
) => {
  try {
    const items = products
      .map((p) => ({
        index: p.forcedIndex,
        item_id: p.skuCode,
        item_name: p.name,
        price: parseFloat(p.price.toFixed(2)),
        quantity: 1,
        ...(!!item_list_name && { item_list_name }),
      }))
      .sort((p1, p2) => (p1.index > p2.index ? 1 : -1));
    const gtmData = getGTMVariablesFromMarket();
    const payload = {
      ...{
        event: 'view_item_list',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: gtmData?.ecom_currency || 'IT',
          ...(!!item_list_name && { item_list_name }),
          items,
        },
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddToCart = (
  lineItem: LineItem,
  quantity: number,
  index: number,
  itemListName: string
) => {
  try {
    const payload = {
      ...{
        event: 'add_to_cart',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: lineItem.currency_code,
          value: lineItem.unit_amount_float * quantity,
          items: gtmProducts([lineItem], false, quantity, index, itemListName),
        },
      },
    };

    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackRemoveFromCart = (lineItem: LineItem, quantity = lineItem.quantity) => {
  try {
    const payload = {
      ...{
        event: 'remove_from_cart',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: lineItem.currency_code,
          value: lineItem.unit_amount_float * quantity,
          items: gtmProducts([lineItem], false, quantity),
        },
      },
    };

    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackStepBeginCheckoutEvent = (order: Order) => {
  if (!order) return;
  try {
    const payload = {
      ...{
        event: 'begin_checkout',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          value: parseFloat(
            order.formatted_total_amount_with_taxes.replace(/[^\d.,]/g, '').replace(',', '.')
          ),
          items: gtmProducts(order.line_items),
        },
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackViewCartEvent = (order: Order) => {
  try {
    const payload = {
      ...{
        event: 'view_cart',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          value: parseFloat(
            order.formatted_total_amount_with_taxes.replace(/[^\d.,]/g, '').replace(',', '.')
          ),
          items: gtmProducts(order.line_items),
        },
      },
    };
    pushEcommAtIndex(1, payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackPurchase = (order: any) => {
  try {
    if (!order) return;
    const { lineItems } = order;
    let shippingCostsItems = lineItems
      ? lineItems.filter((i: any) => i?.item_type == 'adjustments' && i?.name == 'shipping_cost')
      : [];
    const shippingCosts = shippingCostsItems.reduce(
      (accum: any, item: any) => accum + (item.total_amount_float || item.totalAmountFloat),
      0.0
    );
    const payload = {
      ...{
        event: 'purchase',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currencyCode || order.currency_code,
          transaction_id: order.number,
          value: order.totalAmountWithTaxesFloat || order.total_amount_with_taxes_float,
          shipping: shippingCosts,
          tax: order.totalTaxAmountFloat ?? order.total_tax_amount_float,
          coupon: order.couponCode || order.coupon_code || null,
          items: gtmProducts(lineItems),
        },
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddShippingInfo = (order: any) => {
  if (!order) return;
  try {
    const payload = {
      ...{
        event: 'add_shipping_info',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currencyCode || order.currency_code,
          value: parseFloat(
            (order.formattedTotalAmountWithTaxes || order.formatted_total_amount_with_taxes)
              .replace(/[^\d.,]/g, '')
              .replace(',', '.')
          ),
        },
      },
    };
    pushEcommAtIndex(1, payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddPaymentInfo = (order: any) => {
  if (!order) return;
  try {
    const payload = {
      ...{
        event: 'add_payment_info',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currencyCode || order.currency_code,
          value: parseFloat(
            (order.formattedTotalAmountWithTaxes || order.formatted_total_amount_with_taxes)
              .replace(/[^\d.,]/g, '')
              .replace(',', '.')
          ),
        },
      },
    };
    pushEcomm(payload);
  } catch {}
};

export function setGTMVariablesFromMarket(marketNumber: number, locale = 'it') {
  const projectName = config.projectName;
  const { currency, zone_code } = markets.find((c) => {
    const { market_number } = c;
    if (market_number.toString() === marketNumber.toString()) {
      return true;
    }
    return false;
  });

  const gtmData = JSON.stringify({
    country: zone_code,
    language: locale,
    currency: currency,
  });
  localStorageSetItem(`${projectName}${GTM_DATA_KEY}`, gtmData);
}

export function getGTMVariablesFromMarket(): {
  country: string;
  language: string;
  ecom_currency: string;
} | null {
  try {
    const projectName = config.projectName;
    const rawGtmData = localStorageGetItem(`${projectName}${GTM_DATA_KEY}`);
    let gtmData = JSON.parse(rawGtmData);

    if (gtmData.hasOwnProperty('currency')) {
      gtmData.ecom_currency = gtmData.currency;
      delete gtmData.currency;
    }

    gtmData.country = localStorageGetItem(`${projectName}${COUNTRY_CODE_KEY}`);
    return gtmData;
  } catch (error) {
    console.error('getGTMVariablesFromMarket', error);
    return null;
  }
}

const gtmProducts = (
  lineItems: LineItem[],
  is_saleable = false,
  incQuantity?: number,
  index?: number,
  item_list_name?: string
) => {
  const skuLineItems = lineItems.filter((i) => i.item_type == 'skus' || i.item_type == 'bundles');
  return skuLineItems.map((lineItem) => {
    const item = {
      ...(index !== undefined ? { index: index } : {}),
      //@ts-ignore
      item_brand: null,
      item_name: lineItem.name,
      ...(!!item_list_name && { item_list_name }),
      item_id: lineItem.sku_code || lineItem.bundle_code,
      price: parseFloat(lineItem.unit_amount_float.toFixed(2)),
      quantity: incQuantity || lineItem.quantity || lineItem.metadata.maxQuantity,
      ...(is_saleable ? { is_saleable: lineItem.metadata.maxQuantity > 0 ? true : false } : {}),
    };
    return item;
  });
};

const gtmSelectProducts = (
  lineItem: ProductAndBundleDataType,
  item_list_name: string,
  productIndex: number
) => {
  return {
    index: productIndex,
    //@ts-ignore
    item_brand: null,
    item_name: lineItem.name,
    ...(!!item_list_name && { item_list_name }),
    item_id: lineItem.skuCode,
    price: parseFloat(lineItem.price.formatted_amount.replace(/[^\d.,]/g, '').replace(',', '.')),
    quantity: lineItem.maxQuantity,
  };
};

const pushEcomm = (payload: any) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const pushEcommAtIndex = (start: number, payload: any) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.splice(start, 0, { ecommerce: null }, payload);
  }
};
