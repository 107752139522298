import { CategoryListQuery, StringMultiLocaleField } from '@graphql/generated';
import { AltsType, SubPath } from '@model/common';
import _ from 'lodash';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import catsPerMarket from '../../../data/cats-x-markets.json';
import config from '../../../data/config.json';
import marketData from '../../../data/markets.json';
import productsPerMarket from '../../../data/sku-x-market.json';
import structuredDataXMarket from '../../../data/structured-data-x-market.json';

export const subPaths: SubPath = {
  categories: {
    it: 'categorie',
    en: 'categories',
    de: 'kategorien',
    fr: 'categories',
  },
  products: {
    it: 'prodotti',
    en: 'products',
    de: 'produkte',
    fr: 'produits',
  },
};

export function getAvailableCategories(
  categoryList: CategoryListQuery['categoryList'],
  params: NextParsedUrlQuery
): CategoryListQuery['categoryList'] {
  const whiteList = catsPerMarket
    .find(
      (i) => i.marketPaths.currency === params.currency && i.marketPaths.market === params.market
    )
    ?.categories.map((c) => c.id);
  const availableCategories = categoryList.filter((c) => whiteList.includes(c.id));
  return availableCategories;
}

export function getLocales(): string[] {
  return config.availableLocales.locales;
}

export function getAlternativePaths(
  type: string,
  alts: StringMultiLocaleField[],
  parent: StringMultiLocaleField[] = []
): AltsType {
  const paths = alts.reduce((obj, t) => {
    const { locale, value } = t;
    let prefix = '';
    if (type === 'product') {
      prefix = subPaths.products[locale];
    } else if (type === 'category') {
      prefix = subPaths.categories[locale];
      if (parent.length) {
        const parentTranslation = parent.find((a) => a.locale === locale);
        prefix = `${prefix}/${parentTranslation.value}`;
      }
    }
    const path = `${prefix ? prefix + '/' : ''}${value}`;
    return {
      ...obj,
      [locale]: { path, value, locale },
    };
  }, {} as AltsType);
  return paths;
}

export const getPaths = (langs: string[] = []) => {
  const markets = marketData?.map(({ market_name }) => {
    const [market, currency] = market_name.split(' ');
    return { market: market.toLowerCase(), currency: currency.toLowerCase() };
  });

  //if (langs.length === 0) {
  //  return _.orderBy(markets, ['currency', 'market']);
  //}

  const paths = langs
    .map((lang) => {
      return _.uniqBy(markets, 'market').map(({ market, currency }) => {
        return { currency, market, lang };
      });
    })
    .flat();

  return _.orderBy(paths, ['currency', 'market', 'lang']);
};

export const parseUrl = (url: string) => {
  const [protocol, withoutProtocol] = url.split('//');
  const [withoutQuery, query] = withoutProtocol.split('?');
  const [host, ...path] = withoutQuery.split('/');
  const [country, currency, locale] = path;
  const data = {
    protocol,
    host,
    country,
    currency,
    locale,
    query,
    withoutQuery,
    current: url,
    baseUrl: `${protocol}//${host}/${country}/${currency}/${locale}/`,
  };
  return data;
};

export const isHome = (url: string) => {
  const { host, country, currency, locale, withoutQuery } = parseUrl(url);
  const baseUrl = `${host}/${country}/${currency}/${locale}`;
  let result = false;
  if (withoutQuery === baseUrl || withoutQuery == baseUrl + '/') result = true;
  return result;
};

export function getAvailableSkus(prefix: any) {
  const { market, currency } = prefix;
  const whiteList = productsPerMarket.find(
    (item) => item.marketPaths.market === market && item.marketPaths.currency === currency
  );
  const availableSkus = whiteList.results.map((r) => r.code);
  return availableSkus;
}

export function getAvailableProductInfo(prefix: any, product: any) {
  const { market, currency } = prefix;
  const whiteList = structuredDataXMarket.find(
    (item) => item.marketPaths.market === market && item.marketPaths.currency === currency
  );
  const productInfo = whiteList.results.find((r) => r.code == product.skuCode);
  return productInfo;
}

export function getAvailableMarketsForCategory(id: string) {
  const results = catsPerMarket.filter((i) => {
    const ids = i.categories.map((c) => c.id);
    return ids.includes(id);
  });
  return results.map((i) => i.marketPaths);
}
