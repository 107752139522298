export const GIFT_MESSAGE_KEY = '_gift_message';
export const USER_KEY = '_user';
export const CART_ID_KEY = '_cartId';
export const COUNTRY_CODE_KEY = '_countryCode';
export const MARKET_ID_KEY = '_marketId';
export const GTM_DATA_KEY = '_gtmData';
export const GOOGLE_SITE_VERIFICATION_META = 'google-site-verification';
export const FACEBOOK_DOMAIN_VERIFICATION_META = 'facebook-domain-verification';
export const FREE_PAYMENT_METADATA_NAME = 'free_payment';
export const COMMERCE_LAYER_DATE_FORMAT = 'YYYY-MM-DD';
export const TOAST_TIMEOUT = 5000;
export const MAX_CART_QTY_FALLBACK = 1000;
