import ErrorBoundary from '@components/ErrorBoundary';
import DatadogInit from '@components/datadog-init';
import { Toast } from '@components/toast/Toast';
import { AuthUserProvider } from '@lib/b2b/authContext';
import DataProvider from '@lib/context/appContext';
import { isB2BEnabled } from '@utils/config';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import config from '../../data/config.json';
import '../../stylesheets/_style.sass';

export default function MyApp({ Component, pageProps }: AppProps) {
  const [mounted, setMounted] = useState(false);
  const [staging, setStaging] = useState(false);

  useEffect(() => {
    setMounted(true);
    setStaging(window.location.hostname.startsWith('staging'));
    try {
      if (config.configuration.gtmCode) {
        TagManager.initialize({ gtmId: config.configuration.gtmCode });
      }
    } catch (e) {}
  }, []);

  const MyApp = (
    <ErrorBoundary>
      <DatadogInit />
      <DataProvider>
        <Component {...pageProps} />
        {staging && <div className="fixed-label">Staging</div>}
        <Toast />
      </DataProvider>
    </ErrorBoundary>
  );
  return isB2BEnabled ? <AuthUserProvider>{MyApp}</AuthUserProvider> : MyApp;
}
