import { createContext, useContext } from 'react';
import useFirebaseAuth from './useFirebaseAuth';

const authUserContext = createContext({
  user: { email: '' },
  loading: true,
  login: async (data) => false,
  signUp: async () => {},
  logOut: async () => {},
});

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
}
export const useAuth = () => useContext(authUserContext);
